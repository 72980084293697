import Meeting_Icon from "../resources/meeting_icon.png";
function JoinMeetingSuccess() {

    return (

        <div className='container-fluid user-container border-top'>
            <main id="main">
                <section id="breadcrumbs" className="breadcrumbs">
                    <div className="container">
                        <ol>
                            <li><a href="/app">Home</a></li>
                            <li>Join Meeting Status</li>
                        </ol>
                    </div>
                </section>
                <section className="tg-may-account-wrapp tg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 detail text-center" >
                                <img src={Meeting_Icon} width="200" height="200" />
                                <h2>You are redirected to meeting room</h2>
                                <p className="description">Thanks for choosing Boithok!</p>
                                <a href="/app" className="btn btn-success">Back To Home</a>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default JoinMeetingSuccess;