
function NotFound () {
    return (
      <div className="not-found-container">
        <div className="not-found-content">
          <h1>404</h1>
          <h2>Oops! Page Not Found</h2>
          <p>The page you're looking for doesn't seem to exist.</p>
          {/* You can add additional elements, like a button to navigate back */}
        </div>
      </div>
    );
  };
  
export default NotFound;