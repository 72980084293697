import React, { useState } from 'react'
import axios from "axios"
import { useNavigate } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha";

export default function Registration() {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [gender, setGender] = useState("");
    const [password, setPassword] = useState("");
    const [confPassword, setConfPassword] = useState("");
    const [message, setMessage] = useState("");


    const [meetingId, setMeetingId] = useState("");
    const [passcode, setPasscode] = useState("");

    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    const sitekey = process.env.REACT_APP_SITE_KEY;


    const handleRegSubmit = async (event) => {
        event.preventDefault();

        const registrationUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_REGISTRATION_URL;
        console.log("Registration URL : " + registrationUrl);
        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length === 0) {
            console.log("Form Data : " + "No errors in data validation");
        } else {
            console.log("ERR " + errors);
            setErrors(validationErrors);
            return;
        }
        console.log("Hello");
        const response = await axios.post(registrationUrl,
            {
                name,
                email,
                mobile,
                gender,
                password
            }).then(response => {
                console.log("Response data : " + JSON.stringify(response, null, 2));
                navigate("/registration_success");
            }).catch(error => {
                console.error("Error in registration + " + JSON.stringify(error, null, 2));
                setMessage(error.response.data.message);
            })

    }


    const HandleGuestJoinMeetingSubmit = async (event) => {
        let mId = meetingId.replace(/ /g, '');
        const guestMeetingUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GUEST_JOIN_MEETING_URL
            + '?' + 'meetingID=' + mId + '&passcode=' + passcode;

        console.log(guestMeetingUrl);
        axios.post(guestMeetingUrl).then(response => {

            console.log(" Join meeting response: " + JSON.stringify(response, null, 2));
            window.open(response.data.url, "_blank");
            navigate("/join_meeting_success");

        }).catch(error => {
            console.log("Error in request " + JSON.stringify(error, null, 2));
        });
    }


    const isValidEmail = (email) => {
        // Implement a regex or validation logic for email format
        return /\S+@\S+\.\S+/.test(email);
    };

    const isValidMobile = (mobile) => {
        return /^01\d{9}$/.test(mobile);
    };

    const validateForm = () => {

        const validationError = {};
        if (!name) {
            validationError.name = 'Name is required';
        }

        if (!email) {
            validationError.email = 'Email is required';
        } else if (!isValidEmail(email)) {
            validationError.email = 'Invalid email format';
        }

        if (!mobile) {
            validationError.mobile = 'Mobile number is required';
        }
        if (!isValidMobile(mobile)) {
            validationError.mobile = 'Invalid mobile number, must be 11 digit';
        }

        if (!gender) {
            validationError.gender = 'Gender not selected';
        }

        if (!password) {
            validationError.password = 'Password is required';
        } else if (password.length < 6) {
            validationError.password = 'Password at least 6 characters';
        }

        if (password !== confPassword) {
            validationError.confPassword = 'Passwords do not match';
        }
        return validationError;

    }

    function validateCaptcha(value) {
        console.log("Captcha value:", value);
    }


    return (
        <div className='container-fluid user-container border-top'>
            <main id="main">
                <section id="breadcrumbs" className="breadcrumbs">
                    <div className="container">
                        <ol>
                            <li><a href="/app">Home</a></li>
                            <li>Registration</li>
                        </ol>
                    </div>
                </section>
                <section className="tg-may-account-wrapp tg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 detail">
                                <h2>Create your account</h2>
                                <p className="description">Already have an account? <a href={`${process.env.PUBLIC_URL}/login`}>Click here</a> to login</p>
                                <form className="tg-form" onSubmit={handleRegSubmit}>

                                    {message && <div class="alert alert-danger">{message}</div>}

                                    <div className="form-group row">
                                        <label for="name" class="col-sm-3 col-form-label">Name <span style={{ color: "red" }}>*</span></label>
                                        <div class="col-sm-9">
                                            <input type="text" className="form-control shadow-none"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                placeholder="Enter name" />

                                            {errors.name && <span className="text-danger">{errors.name}</span>}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label for="email" class="col-sm-3 col-form-label">Email <span style={{ color: "red" }}>*</span></label>
                                        <div class="col-sm-9">
                                            <input type="email" className="form-control shadow-none"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder="Enter email address" />

                                            {errors.email && <span className="text-danger">{errors.email}</span>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label for="mobile" class="col-sm-3 col-form-label">Mobile Number <span style={{ color: "red" }}>*</span></label>
                                        <div class="col-sm-9">
                                            <input type="mobile" className="form-control shadow-none"
                                                value={mobile}
                                                onChange={(e) => setMobile(e.target.value)}
                                                placeholder="Enter mobile number"
                                                minLength={11}
                                                maxLength={11}
                                                pattern="01[0-9]{9}" />

                                            {errors.mobile && <span className="text-danger">{errors.mobile}</span>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className='pr-2' class="col-sm-3 col-form-label">Gender <span style={{ color: "red" }}>*</span></label>
                                        <div class="col-sm-9">
                                            <input
                                                type="radio"
                                                name="gender"
                                                value="Male"
                                                onChange={(e) => setGender(e.target.value)} />
                                            <label className='px-2'> Male </label>

                                            <input
                                                type="radio"
                                                name="gender"
                                                value="Female"
                                                onChange={(e) => setGender(e.target.value)} />
                                            <label className='px-2'> Female </label>

                                            <div>
                                                {errors.gender && <span className="text-danger">{errors.gender}</span>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label for="inputAddress2" class="col-sm-3 col-form-label">Password <span style={{ color: "red" }}>*</span></label>
                                        <div class="col-sm-9">
                                            <input type="password" className="form-control shadow-none"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                placeholder="Enter password" />

                                            {errors.password && <span className="text-danger">{errors.password}</span>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label for="inputAddress2" class="col-sm-3 col-form-label">Confirm Password <span style={{ color: "red" }}>*</span></label>
                                        <div class="col-sm-9">
                                            <input type="password" className="form-control shadow-none"
                                                value={confPassword}
                                                onChange={(e) => setConfPassword(e.target.value)}
                                                placeholder="Re-enter password" />

                                            {errors.confPassword && <span className="text-danger">{errors.confPassword}</span>}
                                            <ReCAPTCHA className="pt-3"
                                                sitekey={sitekey}
                                                onChange={validateCaptcha}
                                                required />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label for="inputAddress2" className="col-sm-3 col-form-label"></label>
                                        <div class="col-sm-9">
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-4" >
                                {/* <img src="assets/img/registration.jpg" alt="Holograms" width="100%" height="100%" /> */}
                                {/* <p>New to Boithok? </p>
                                            <a href="/registration">Register here</a> */}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}
