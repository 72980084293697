import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Join_Meeting_Icon from "../resources/join_meeting_icon.png";
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

export default function Home() {

  const [meetingId, setMeetingId] = useState("");
  const [passcode, setPasscode] = useState("");

  const [mobileNo, setMobileNo] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [showContactForm, setShowContactForm] = useState(true);
  const [contactMassage, setContactMessage] = useState("");

  const navigate = useNavigate();

  let jwt = localStorage.getItem('token');

  const header = {
    headers: {
      'Authorization': 'Bearer ' + jwt
    }
  }
  console.log("Authorization Meeting History" + header);
  const purchase_url = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PAYMENT_API;
  const contact_url = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_CONTACT_API;
  console.log("Purchase url : " + purchase_url);

  const HandleGuestJoinMeetingSubmit = async (event) => {
    event.preventDefault();
    let mId = meetingId.replace(/ /g, '');

    const guestMeetingUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GUEST_JOIN_MEETING_URL + '?' + 'meetingID=' + mId + '&passcode=' + passcode;
    console.log("guestMeetingUrl: " + guestMeetingUrl);
    axios.post(guestMeetingUrl).then(response => {
      console.log(" Join meeting response: " + JSON.stringify(response, null, 2));
      window.open(response.data.url, "_blank");
      navigate("/join_meeting_success");
    }).catch(error => {
      console.log("Error in request " + JSON.stringify(error, null, 2));
    });
  }

  const HandleContactFormSubmit = async (event) => {
    event.preventDefault();
    let data = new FormData();
    data.append('mobile', mobileNo);
    data.append('name', name);
    data.append('email', email);
    data.append('message', message);
    data.append('subject', subject);
    console.log("contact url: " + contact_url);
    axios.post(contact_url, data)
      .then(response => {
        console.log("Response data : " + JSON.stringify(response, null, 2));
        if (response.status == 200) {
          setContactMessage("Your message has beed sent successfully");
          setShowContactForm(false);
        }
      }).catch(error => {
        console.error("Error in contact + " + JSON.stringify(error, null, 2));
        setContactMessage("There is a error in server");
        setShowContactForm(false);
      })
  }

  function purchasePackage(id) {
    let p_url = purchase_url + id;
    console.log("Purchase url---> : " + p_url);
    axios.get(p_url, header)
      .then(response => {
        console.log(" URL " + response.data.url);
        window.open(response.data.url, "_self");
        console.log("Success ====>  " + JSON.stringify(response, null, 2));
        console.log('Result ' + response);
      })
      .catch(error => {
        console.log("Request is not get any response");
        console.log("***************** Error Here : " + JSON.stringify(error, null, 2));
        if (error.response.status == "401") {
          localStorage.removeItem("token");
          navigate("/login");
        }
      })
  }



  return (
    <>
      <main id="main">

        <section id="hero">

          <div className="container">
            <div className="row">
              <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center" data-aos="fade-up">
                <div>
                  <h1>Streamline Communication, Increase employee engagement & Improve Productivity with Boithok</h1>
                  <h2></h2>
                  <div className="guest-join-btn">
                    <button className="btn-get-started scrollto" data-toggle="modal" data-target="#exampleModal">Join As A Guest</button>
                  &nbsp;OR&nbsp;<Link to="/login" className="btn btn-success">Login</Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="fade-left">
                <Swiper 
                  modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={50}
                  slidesPerView={1}
                  // navigation
                  pagination={{ clickable: true }}
                  autoplay={{ delay: 3000, disableOnInteraction: false }}
                  // scrollbar={{ draggable: true }}
                >
                  <SwiperSlide><img src={`${process.env.PUBLIC_URL}/assets/img/slider/wsis-prize-vc.jfif`} style={{width:"100%", height:"80%"}} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={`${process.env.PUBLIC_URL}/assets/img/slider/advisor-vc.png`} style={{width:"100%", height:"80%"}} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={`${process.env.PUBLIC_URL}/assets/img/slider/hsm-vc.png`} style={{width:"100%", height:"80%"}} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={`${process.env.PUBLIC_URL}/assets/img/slider/ed-vc.png`} style={{width:"100%", height:"80%"}} alt="" /></SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>

        </section>

        {/* <!-- ======= About Section ======= --> */}
        <section id="about" className="about">
          <div className="container">
            <h3>About Boithok</h3>
            <div className="row">
              <div className="col-lg-6" data-aos="zoom-in">
                <img src="assets/img/wsis-cert.jpeg" className="img-fluid" alt="" />
              </div>

              <div className="col-lg-6 d-flex flex-column justify-contents-center" data-aos="fade-left">
                <div className="content pt-4 pt-lg-0">
                  {/* <h3>About Boithok</h3>  */}
                  <p className="fst-italic" style={{ textAlign: "justify" }}>
                    Boithok is an award-winning, web-based video conferencing platform hosted at the National Data Center and maintained by the BNDA team. It enables users to share their needs and thoughts through secure, real-time video conferencing, creating a realistic virtual meeting experience. The platform ensures complete security and confidentiality of user data, with no data or recordings stored outside the country. Boithok is also highly user-friendly, allowing users to join meetings effortlessly by clicking a meeting link within seconds.
              </p>
                  <ul>
                    <li><i className="bi bi-check-circle"></i> Running meetings for unlimited duration.</li>
                    <li><i className="bi bi-check-circle"></i> Hosted in our own Data Center.</li>
                    <li><i className="bi bi-check-circle"></i> Recordings are saved safely, can be downloaded at any time.</li>
                    <li><i className="bi bi-check-circle"></i> All necessary features for a video conferencing are present.</li>
                    <li><i className="bi bi-check-circle"></i> User can participate in meetings without prior registration.</li>
                  </ul>
                  <p>

                  </p>
                </div>
              </div>
              <div> Ministry of foreign affairs, ICT Division, BCC, A2I and some other govt. offices are using Boithok on regular basis. Honorable ICT state minister instructed to extend its usage across government offices after incorporating user comments and feedback. Around 2500+ successful meetings has already been held at Boithok platform so far.
			</div>
            </div>

          </div>
        </section>
        {/* <!-- End About Section --> */}

        {/* <!-- ======= Services Section ======= --> */}
        <section id="services" className="services section-bg">
          <div className="container">

            <div className="section-title" data-aos="fade-up">
              <h2>Features</h2>
              <p>Boithok is a feature-riched video conferencing solution that helps you to stay connected & collaboration with team members securely and efficiently</p>
            </div>

            <div className="row">
              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in">
                <div className="icon-box icon-box-pink">
                  <div className="icon"><i className="bx bxl-dribbble"></i></div>
                  <h4 className="title"><a href="">High-Quality Video</a></h4>
                  <p className="description">Experience crystal-clear video and audio for your meetings, presentations, and more.</p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="100">
                <div className="icon-box icon-box-cyan">
                  <div className="icon"><i className="bx bx-file"></i></div>
                  <h4 className="title"><a href="">Collaboration</a></h4>
                  <p className="description">Easily collaborate with your team members, share content, and work together in real-time.</p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                <div className="icon-box icon-box-green">
                  <div className="icon"><i className="bx bx-tachometer"></i></div>
                  <h4 className="title"><a href="">Secure and Private</a></h4>
                  <p className="description">We prioritize the security of your conversations with top-notch encryption and privacy measures.</p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                <div className="icon-box icon-box-blue">
                  <div className="icon"><i className="bx bx-mobile-alt"></i></div>
                  <h4 className="title"><a href="">Mobile Apps</a></h4>
                  <p className="description">Stay connected on the go with our feature-rich mobile apps for iOS and Android.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                <div className="icon-box icon-box-blue">
                  <div className="icon"><i className="bx bx-video-recording"></i></div>
                  <h4 className="title"><a href="">Recording</a></h4>
                  <p className="description">Record your meetings and webinars for future reference or sharing with others..</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                <div className="icon-box icon-box-green">
                  <div className="icon"><i className="bx bx-share"></i></div>
                  <h4 className="title"><a href="">Screen Sharing</a></h4>
                  <p className="description">Share your screen during meetings and presentations for effective communication.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                <div className="icon-box icon-box-cyan">
                  <div className="icon"><i className="bx bx-group"></i></div>
                  <h4 className="title"><a href="">Webinars Hosting</a></h4>
                  <p className="description">Host webinars with ease, engage with your audience, and conduct Q&A sessions..</p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                <div className="icon-box icon-box-pink">
                  <div className="icon"><i className="bx bx-list-check"></i></div>
                  <h4 className="title"><a href="">Attendance</a></h4>
                  <p className="description">Get the Participants list of each meeting..</p>
                </div>
              </div>


            </div>

          </div>
        </section>
        {/* <!-- End Services Section --> */}


        {/* <!-- ======= F.A.Q Section ======= --> */}
        <section id="faq" className="faq">
          <div className="container">

            <div className="section-title" data-aos="fade-up">
              <h2>Frequently Asked Questions</h2>
            </div>

            <ul className="faq-list">

              <li>
                <div data-bs-toggle="collapse" className="collapsed question" href="#faq1">How can I start a meeting? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    Its very easy. You can start a meeting in following way:
                    In the Boithok welcome page, there is an input-box for the meeting name. Beside the input-box, there is a play button. After setting your participant name and clicking the “Join Meeting” and subsequently providing the credentials, one can start the meeting.
              </p>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq2" className="collapsed question">Can we use Bangla meeting names in Boithok?? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    No, The meeting name has to be English. There are some technical problems (as: meeting link sharing, and some others) if Bangla meeting name is used. So, all users are requested to use English meeting names.
              </p>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq3" className="collapsed question"> Can the meeting name be changed later in the meeting? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    No, Once a meeting is created, the meeting name can not be changed. The meeting name uniquely identifies a meeting. Changing the meeting name will generate a new meeting and link. So, if you change the name, you have to resend the new meeting link to all participants. Please take extra cautions, be careful so that you type the correct meeting name.
              </p>
                </div>
              </li>
              <li>
                <div data-bs-toggle="collapse" href="#faq3" className="collapsed question"> How can a moderator download the recording of a meeting from Boithok? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    In the Boithok welcome page, there is a button named “Recording Download”.By clicking the button, you will get a pop-up demanding Boithok credentials. After authentication, one is redirected to the tab where you can download the recording by entering the exact name of the meeting.
              </p>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq4" className="collapsed question"> In Boithok, how can a moderator control whether anyone can join or not? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    A moderator will have a button in the toolbox named “More Actions”. After clicking the button, he will be shown a menu list including “Security Options” as a menu. In this menu, he will be able to enable the lobby or add password to the meeting. After enabling the lobby or the password, every participant can not join without the moderator's consent or entering the password set by the moderator for the meeting.
              </p>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq5" className="collapsed question">What are the differences between “Desktop App” and “Browser-based Boithok” ? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq5" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    During sharing-screen in presentation view in web-browser, to access toolbar options, one has to switch off the presentation mode and go to the browser to access toolbox options. Whereas in the Desktop app, one will get the toolbox options in the presentation window. So sharing screen is comparatively easier in Desktop app.
              </p>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq6" className="collapsed question">How can one share his/her screen in the presentation view ? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq6" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    To present a slide or a pdf in the presentation view, first one has to open the file in presentation mode with a corresponding application like (Powerpoint or PdfReader). Then using “Alt + Tab” , he has to switch to web browser. In Boithok share screen option, he will get his intended application with presentation mode. Choosing this option, he will be able to share the intended application. To stop sharing, he can press “Esc” button.
              </p>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq7" className="collapsed question">What are the procedures one should follow to mitigate the issue of camera and speaker? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq7" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    The main point is that the camera and speaker should be connected with your system. There will be a pop up when someone will enter the meeting questioning whether he wants to allow camera and speaker or block. If someone blocks the camera and speaker, he will get “View Site Information” icon(the one with the lock shape) in the URL bar for this site where he can allow the camera and speaker. If all are in right position but still one is facing problems , he can reconnect the camera and the speaker or rejoin the meeting.
              </p>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq8" className="collapsed question">How can a moderator get the attendance list of the meeting?  <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq8" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    There is a button at the top of the meeting page to get the participant list. After clicking the button, a pop up will be opened. The moderator will get a button named “Download Participant List” button. Just clicking the button will download the attendance list or redirect to the attendance page from where he can download. One other option is to click the “Leave The Meeting” which will end-meeting pop up. There the moderator will also get the “Download Participant List” Button.
              </p>
                </div>
              </li>
              <li>
                <div data-bs-toggle="collapse" href="#faq9" className="collapsed question"> How can I get credentials (username & password) for hosting a meeting? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq9" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    You can register from this Boithok website and request for Host Credentials. Go to register form and give your information. After verifying, Boithok Team will contact you on your provided Email and Phone number.
              </p>
                </div>
              </li>


            </ul>

          </div>
        </section>

        {/* <!-- ======= Contact Section ======= --> */}
        <section id="contact" className="contact section-bg">
          <div className="container">

            <div className="section-title" data-aos="fade-up">
              <h2>Contact Us</h2>
            </div>

            <div className="row">

              <div className="col-lg-5 d-flex align-items-stretch" data-aos="fade-right">
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Location:</h4>
                    <p>E-14 10, ICT TOWER, Dhaka 1207, Bangladesh</p>
                  </div>

                  <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>info@boithok.com</p>
                  </div>

                  <div className="phone">
                    <i className="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p>01911707370</p>
                  </div>

                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14604.471835144393!2d90.3743935!3d23.7788135!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c0b33134d9cb%3A0xdaf562015a13a85!2sBangladesh%20Computer%20Council!5e0!3m2!1sen!2sbd!4v1713870628377!5m2!1sen!2sbd"
                    frameBorder="0" style={{ border: "0", width: "100%", height: "290px" }} allowFullScreen></iframe>
                </div>

              </div>

              <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch" data-aos="fade-left">
                {showContactForm &&
                  <form className="php-email-form" onSubmit={HandleContactFormSubmit}>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label for="name">Your Name</label>
                        <input type="text" name="name" className="form-control" value={name} onChange={(e) => setName(e.target.value)} required />
                      </div>
                      <div className="form-group col-md-6 mt-3 mt-md-0">
                        <label for="name">Your Email</label>
                        <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} required />
                      </div>
                    </div>
                    <div className="form-group mt-1">
                      <label for="name">Mobile Number</label>
                      <input type="mobile" className="form-control" value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} required />
                    </div>
                    <div className="form-group mt-1">
                      <label for="name">Subject</label>
                      <input type="text" className="form-control" value={subject} onChange={(e) => setSubject(e.target.value)} required />
                    </div>
                    <div className="form-group mt-3">
                      <label for="name">Message</label>
                      <textarea className="form-control" name="message" rows="5" value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
                    </div>
                    <div className="my-3">
                      <div className="loading">Loading</div>
                      <div className="error-message"></div>
                      <div className="sent-message">Your message has been sent. Thank you!</div>
                    </div>
                    <div className="text-center"><button className='btn btn-success' type="submit">Send Message</button></div>
                  </form>
                }
                {!showContactForm && contactMassage}
              </div>

            </div>

          </div>
        </section>

        <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Join in a meeting</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={HandleGuestJoinMeetingSubmit}>
                <div className="modal-body invitaion-info text-center">
                  <img src={Join_Meeting_Icon} height="120" width="120" className="p-3 " />
                  <input type="text" className="form-control" value={meetingId} onChange={(e) => setMeetingId(e.target.value)} placeholder="Enter meeting ID" />
                  <input type="text" className="form-control" value={passcode} onChange={(e) => setPasscode(e.target.value)} placeholder="Enter passcode" />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-primary">Join Meeting</button>
                </div>
              </form>
            </div>
          </div>
        </div>

      </main>
    </>
  )
}
