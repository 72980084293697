import React, { useState, useEffect } from 'react'
import axios from "axios";
import { spaceAfter4digit } from './date_time';
import { useNavigate } from "react-router-dom";
import Video_Conf from "../resources/male_avt.jpg";

export default function Profile() {
      const [flag, setFlag] = useState(true);

      //******************  User Profile information ************//

      const [image, setImage] = useState('');
      const [file, setFile] = useState('');
      const [meetingId, setMeetingId] = useState('');
      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [mobile, setMobile] = useState('');
      const [passcode, setPasscode] = useState('');
      const [invitationLink, setInvitationLink] = useState('');
      const [organization, setOrganization] = useState('');
      const [address, setAddress] = useState('');
      const [designation, setDesignation] = useState('');
      const [activePackage, setActivePackage] = useState('');


      //*******************************************************//

      const navigate = useNavigate();
      const userInfoUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_USER_INFO_URL;
      const userUpdateUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_USER_UPDATE_URL;
      let jwt = localStorage.getItem('token');

      const header = {
          headers : {
              'Authorization' : 'Bearer '+ jwt
          }
      }   

      useEffect(() => {       
          axios.get(userInfoUrl, header)
          .then(response =>{
              console.log("Success ====>  " + JSON.stringify(response , null, 2));            
              setName(response.data.name);
              setEmail(response.data.email);
              setMobile(response.data.mobile);
              setPasscode(response.data.passCode);
              setInvitationLink(response.data.invitationLink);
              setMeetingId(response.data.personalMeetingID);
              setOrganization(response.data.organization);
              setDesignation(response.data.designation);
              setAddress(response.data.address);
              setImage(response.data.avatar);
              setActivePackage(response.data.activePackage);
              localStorage.setItem("designation", response.data.designation);
              localStorage.setItem("organization", response.data.organization);
          })
          .catch(error =>{
              console.log("Request is not get any response");
              console.log( "***************** Error Here : "+ JSON.stringify(error , null, 2));  
              if(error.response.status == "401")  {
                  localStorage.removeItem("token");
                  navigate("/login");
              }
          })

      },[])

      const handleProfileImage = (event) =>{
          console.log(event.target.files[0]);
          const selectedImage = event.target.files[0];
          setFile(selectedImage);
          if(selectedImage){
              const imageUrl = URL.createObjectURL(selectedImage);
              console.log("Image Selected");
              setImage(imageUrl);
          }
      }
      

      const handlProfileUpdate = async (event) => {
          event.preventDefault();
          console.log("Hello");
          let data = new FormData();
          data.append('mobile', mobile);
          data.append('organization',organization);
          data.append('designation',designation);
          data.append('address',address);
          data.append('file', file);

          const response = axios.post(userUpdateUrl, 
          data, header)
          .then(response =>{
              console.log("Response data : " + JSON.stringify(response, null, 2));    
              setFlag(true);
          }).catch (error => {
              console.error("Error in registration + " + JSON.stringify(error, null, 2));
              
              // if(error.response.status == "400"){
              //     setMessage("This user already registered");
              // }
              // else if(error.response.status == null){
              //     setMessage("Server unreachable");
              // }
              // else if(error.data.status === "401")  {
              //     navigate("/login");
              // }
              console.error("Error code " + error.response.status);
          })   

      }

  return (
    
    <div className="address-form">
        <div className="inner">
        {flag && 
            <div className='row w-100'>       
                <div className='col-md-3 col-sm-12 p-2'>
                    <div><img src={image} className='img-round' alt="Profile" height="100px" width="100px" /></div>
                </div>
                <div className='col-md-9 col-sm-12 text-right p-3'>
                    <button className='btn btn-primary' onClick={() => setFlag(false)}>Edit</button>
                </div>    
            
                <div className='col-12 bar-text'>
                    <p className='text-pad'>Personal Info</p>
                </div>                
            
                <div className='col-md-3 col-sm-12 p-2'><b>Email</b></div>
                <div className='col-md-9 col-sm-12 p-2'>{email}</div>
            
                <div className='col-md-3 col-sm-12 p-2'><b>Mobile</b></div>
                <div className='col-md-9 col-sm-12 p-2'>{mobile}</div>
            
                <div className='col-md-3 col-sm-12 p-2'><b>Personal Meeting Id</b></div>
                <div className='col-md-9 col-sm-12 p-2'>{spaceAfter4digit(meetingId)}</div>
            
                <div className='col-md-3 col-sm-12 p-2'><b>Passcode</b></div>
                <div className='col-md-9 col-sm-12 p-2'>{passcode}</div>
            
                <div className='col-md-3 col-sm-12 p-2'><b>Invitation Link</b></div>
                <div className='col-md-9 col-sm-12 p-2'>{invitationLink}</div>
            
                <div className='col-12 bar-text'>
                    <p>Professional Info</p>
                </div>
            
                <div className='col-md-3 col-sm-12 p-2'><b>Organization</b></div>
                <div className='col-md-9 col-sm-12 p-2'>{organization}</div>
            
                <div className='col-md-3 col-sm-12 p-2'><b>Designation</b></div>
                <div className='col-md-9 col-sm-12 p-2'>{designation}</div>
            
                <div className='col-md-3 col-sm-12 p-2'><b>Address</b></div>
                <div className='col-md-9 col-sm-12 p-2'>{address}</div>
            
                <div className='col-12 bar-text'>
                    <p className='text-pad'>Package Info</p>
                </div>
            
                <div className='col-md-3 col-sm-12 p-2'><b>Package Name</b></div>
                <div className='col-md-9 col-sm-12 p-2'>{activePackage.packageName}</div>
            
                <div className='col-md-3 col-sm-12 p-2'><b>Start Time</b></div>
                <div className='col-md-9 col-sm-12 p-2'>{new Date(activePackage.startTime).toLocaleString()}</div>
            
                <div className='col-md-3 col-sm-12 p-2'><b>End Time</b></div>
                <div className='col-md-9 col-sm-12 p-2'>{new Date(activePackage.endTime).toLocaleString()}</div>
            </div>
        }

        {!flag &&  
            <form>
                <div className='row w-100'>                                                
                    <div className='col-3 p-3'><h3>Edit Profile</h3></div>
                    <div className='col-9 text-right p-3'>
                        <button className='btn btn-success' onClick={()=>setFlag(true)} >Cancel</button>
                    </div>                        
                    <div className='col-3 p-3'>
                        <div>{image? <img src={image} className='img-round'/> : <img src={image} className='img-round'/>  }</div>
                        <div><input type='file' name='file' className='pt-4' onChange={handleProfileImage}/></div>
                    </div>
                    <div className='col-9 p-3 border-pad'><p className=''>{name}</p></div>

                    <div className='col-12 bar-text'><p className='text-pad'>Personal Info</p></div>                

                    <div className='col-md-3 col-sm-12 p-2'><b>Email</b></div>
                    <div className='col-md-9 col-sm-12 p-3'>{email}</div>

                    <div className='col-md-3 col-sm-12 p-2'><b>Mobile</b></div>
                    <div className='col-md-9 col-sm-12 p-3'>
                        <input type='text' name='mobile' className='form-control'
                               defaultValue={mobile} onChange={(e) => setMobile(e.target.value)} />
                    </div>

                    <div className='col-md-3 col-sm-12 p-2'><b>Personal Meeting Id</b></div>
                    <div className='col-md-9 col-sm-12 p-3'>{spaceAfter4digit(meetingId)}</div>

                    <div className='col-md-3 col-sm-12 p-2'><b>Passcode</b></div>
                    <div className='col-md-9 col-sm-12 p-3'>{passcode}</div>

                    <div className='col-md-3 col-sm-12 p-2'><b>Invitation Link</b></div>
                    <div className='col-md-9 col-sm-12 p-3'>{invitationLink}</div>

                    <div className='col-12 bar-text'><p>Professional Info</p></div>
                    
                    <div className='col-md-3 col-sm-12 p-2'><b>Organization</b></div>
                    <div className='col-md-9 col-sm-12 p-3'>
                        <input type='text' name='organization' className='form-control' 
                               defaultValue={organization} onChange={(e) => setOrganization(e.target.value)}/>
                    </div>                    
                    
                    <div className='col-md-3 col-sm-12 p-2'><b>Designation</b></div>
                    <div className='col-md-9 col-sm-12 p-3'>
                        <input type='text' name='designation' className='form-control' 
                               defaultValue={designation} onChange={(e) => setDesignation(e.target.value)} />
                    </div>
                    
                    <div className='col-md-3 col-sm-12 p-2'><b>Address</b></div>
                    <div className='col-md-9 col-sm-12 p-3'>
                        <input type='text' name='address' className='form-control' 
                        defaultValue={address} onChange={(e) => setAddress(e.target.value)} />
                    </div>
                    <div className='col-md-3 col-sm-12 p-2'></div>
                    <div className='col-md-3 col-sm-12 p-2'>
                        <button type='submit' className='btn btn-success btn-block' onClick={handlProfileUpdate}>Save</button>
                    </div>                                                                                      
                    
                </div>
                </form>
            }
        </div>
    </div>

  )
}
