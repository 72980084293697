import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';

export default function Navbar() {

  const navigate = useNavigate();

  const [navVisible, setNavVisible] = useState(true);

  const handleMenuClick = () => {
    setNavVisible(!navVisible);
  };

  const handleLogout = (e) => {
    localStorage.removeItem("token");
    navigate("/login");
  }

  const [navMenuClassName, setNavMenuClassName] = useState('navbar order-last order-lg-0');
  const [listMenuClassName, setListMenuClassName] = useState('bi mobile-nav-toggle bi-list');

  // Function to handle the onClick event and toggle the className
  const handleMenuToggle = () => {
    setNavMenuClassName(prev =>
      prev === 'navbar order-last order-lg-0' ? 'navbar order-last order-lg-0 navbar-mobile' : 'navbar order-last order-lg-0'
    );

    setListMenuClassName(prev =>
      prev === 'bi mobile-nav-toggle bi-list' ? 'bi mobile-nav-toggle bi-x' : 'bi mobile-nav-toggle bi-list'
    );
  };

  return (
    <header id="header" className="fixed-top d-flex align-items-center">
      <div className="container d-flex align-items-center">

        <div className="logo me-auto">
          <a className="navbar-brand" href={`${process.env.PUBLIC_URL}`}>
            <img src={`${process.env.PUBLIC_URL}/assets/img/Boithok-Green-Logo.png`} height="60" width="100" />
          </a>
        </div>

        <nav id="navbar" className={navVisible ? navMenuClassName : 'hidden'}>
          <div>
            <ul>
              <li><a className="nav-link scrollto active" href="/app">Home</a></li>
              <li><a className="nav-link scrollto" href="/app#about">About Boithok</a></li>
              <li><a className="nav-link scrollto" href="/app#services">Features</a></li>
              {/* <li><a className="nav-link scrollto"  href="/#pricing">Pricing</a></li> */}
              <li><a className="nav-link scrollto" href="/app#faq">FAQ</a></li>
              <li><a className="nav-link scrollto" href="/app#contact">Contact</a></li>
              {
                localStorage.getItem('token') ? (
                  <>
                    <li><Link className="nav-link scrollto" to="/user_dashboard">Dashboard</Link></li>
                    <li><a className="nav-link scrollto" onClick={handleLogout}><button className="button-primary">Logout</button></a></li>
                  </>
                )
                  :
                  (
                    <>
                      <li><Link className="nav-link scrollto" to="/registration">Registration</Link></li>
                      <li><Link className="nav-link scrollto" to="/login"><button className="button-primary">Login</button></Link></li>
                    </>
                  )
              }


            </ul>
            <i className={listMenuClassName} onClick={handleMenuToggle}></i>
          </div>
        </nav>
      </div>
    </header>
  );
}
