import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Profile from './profile';
import StartMeeting from './start_meeting';
import JoinMeeting from './join_meeting';
import Scheduler from './scheduler';
import UpcomingMeeting from './upcoming_meeting';
import MeetingHistory from './meeting_history';
import PricePlan from './price_plan';
import Setting from './setting';





export default function UserDashboard() {
    const navigate = useNavigate();
    const [compo, setComp] = useState(<StartMeeting />);

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate("/login");
        }
    }, []);

    let jwt = "";
    let meetingId = "";
    let name = "";

    jwt = localStorage.getItem('token');
    meetingId = localStorage.getItem('meetingId');
    name = localStorage.getItem('name');
    console.log("Token : " + jwt);

    // const handleUserTab = (temp) => {
    //     console.log("This is test");
    // }

    return (
        <div>
            <div className='container-fluid user-container border-top'>
                <main id="main">

                    <section id="breadcrumbs" className="breadcrumbs">
                        <div className="container">
                            <ol>
                                <li><a href="/app">Home</a></li>
                                <li>Dashboard</li>
                            </ol>
                        </div>
                    </section>
                    <section className="tg-may-account-wrapp tg">
                        <div className="inner">
                            <div className="tg-account">
                                <div className="account-banner">
                                    <div className="inner-banner">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-8 detail">
                                                    <div className="inner">
                                                        <h2>Welcome, {name}</h2>
                                                        {/* <p className="description">Welcome, {name}</p> */}
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="nav-area">
                                                <ul className="nav nav-tabs" id="myTab" role="tablist">                                                    
                                                    <li className="nav-item">
                                                        <a className="nav-link active" id="start-meeting-tab" data-toggle="tab" href="#start-meeting" role="tab" aria-controls="meeting-history" aria-selected="true" ><i className="fas fa-file-invoice"></i> <span>Start Meeting</span></a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="join-meeting-tab" data-toggle="tab" href="#join-meeting" role="tab" aria-controls="address" aria-selected="false" ><i className="fas fa-map-marker-alt"></i> <span>Join Meeting</span></a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="schedule-meetings-tab" data-toggle="tab" href="#schedule-meeting" role="tab" aria-controls="address" aria-selected="false" ><i className="fas fa-map-marker-alt"></i> <span>Schedule Meeting</span></a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="change-meeting-tab" data-toggle="tab" href="#upcoming-meeting" role="tab" aria-controls="address" aria-selected="false" ><i className="fas fa-map-marker-alt"></i> <span>Upcoming Meeting</span></a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="history-tab" data-toggle="tab" href="#meeting-history" role="tab" aria-controls="address" aria-selected="false" ><i className="fas fa-map-marker-alt"></i> <span>Meeting History</span></a>
                                                    </li>
                                                    {/* <li className="nav-item">
                                                        <a className="nav-link" id="price-tab" data-toggle="tab" href="#price-plan" role="tab" aria-controls="address" aria-selected="false" ><i className="fas fa-map-marker-alt"></i> <span>Update Plan</span></a>
                                                    </li> */}
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="profiles-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false"><i className="fas fa-tachometer-alt"></i> <span>Update Profile</span></a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="setting-tab" data-toggle="tab" href="#setting" role="tab" aria-controls="address" aria-selected="false" ><i className="fas fa-map-marker-alt"></i> <span>Change Password</span></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs tg-tabs-content-wrapp">
                                    <div className="inner">
                                        <div className="container">
                                            <div className="inner">
                                                <div className="tab-content" id="myTabContent">                                                    
                                                    <div className="tab-pane fade show active pt-3" id="start-meeting" role="tabpanel" aria-labelledby="my-order">
                                                        <StartMeeting />
                                                    </div>
                                                    <div className="tab-pane fade pt-3" id="join-meeting" role="tabpanel" aria-labelledby="my-address">
                                                        <JoinMeeting />
                                                    </div>
                                                    <div className="tab-pane fade pt-3" id="schedule-meeting" role="tabpanel" aria-labelledby="my-address">
                                                        <Scheduler />
                                                    </div>
                                                    <div className="tab-pane fade pt-3" id="upcoming-meeting" role="tabpanel" aria-labelledby="my-order">
                                                        <UpcomingMeeting />
                                                    </div>
                                                    <div className="tab-pane fade pt-3" id="meeting-history" role="tabpanel" aria-labelledby="my-order">
                                                        <MeetingHistory />
                                                    </div>
                                                    {/* <div className="tab-pane fade pt-3" id="price-plan" role="tabpanel" aria-labelledby="my-order">
                                                        <PricePlan />
                                                    </div> */}
                                                    <div className="tab-pane fade pt-3" id="profile" role="tabpanel" aria-labelledby="dashboard-link">
                                                        <Profile />
                                                    </div>
                                                    <div className="tab-pane fade pt-3" id="setting" role="tabpanel" aria-labelledby="my-order">
                                                        <Setting />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    )
}
