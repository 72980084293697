export function todaysDate(){
    const currentDate = new Date();
    const day = currentDate.getDate();
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const month = monthNames[currentDate.getMonth()];
    const year = currentDate.getFullYear();
    const todayDate = [day, month, year];
    return todayDate;
}

export function currentTime(){
    const currentDate = new Date();
    const formattedTime = currentDate.toLocaleTimeString();
    return formattedTime;
}

export function spaceAfter4digit(originalString){
    if(originalString == null) return;
    let formatedString ="";
    for(var i=0; i< originalString.length; i+=4){
      formatedString += originalString.substring(i, i+4) + " ";
    }
    return formatedString;  
}