import  { useState, useEffect } from 'react'
import axios from "axios";

export default function Setting() {
    const[currentPassword, setCurrentPassword] = useState();
    const[password, setPassword] = useState();
    const[rePassword, setRePassword] = useState();
    const[flag, setFlag] = useState(true);

    const [error, setError] = useState({});
    const jwt = localStorage.getItem('token');

    const passwordChangeUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UPDATE_PASSWORD;


    const header = {
      headers : {
          'Authorization' : 'Bearer '+ jwt
      }
    }    

    const handleSubmit =async (event)=>{
        event.preventDefault();
        setError('');

        const validationError = formValidation();
        
        if(Object.keys(validationError).length === 0){
          console.log("Change password form has no error");
        }
        else{
          setError(validationError);
          return;
        }

        const response = await axios.put(passwordChangeUrl,
        {   currentPassword,
            password,
            rePassword
        }, header).then(response =>{
            console.log("Response data : " + JSON.stringify(response, null, 2));    
            if(response.status === 200){
              console.log("Password change successfully");
            }
            setFlag(false);
        }).catch (error => {
            console.error("Error in registration + " + JSON.stringify(error, null, 2));            
            let serverError =  {};
            if(error.response.status == "400"){
                serverError.currentPassword = "Current password doesn't match";
                setError(serverError);
            }           
        }) 
    }

    const formValidation =()=>{
      
      const validationError = {};
      
      if(currentPassword === null || currentPassword === ''|| currentPassword === undefined){
        validationError.currentPassword = "Current Password can't be empty";
      }

      if(password === null || password === '' || password === undefined ){
        validationError.password =  "Password can't be empty";
      }
      
      if(rePassword === null || rePassword === '' || rePassword === undefined ){
        validationError.rePassword = "Confirm Password can't be empty";
      }
    
      if(rePassword != password){
        validationError.password = "New password doesn't match with Confirm password";
      }
      return validationError;
    }



  return (
    <div className="container form-width">
      <h4 className='mt-3'>Change your password</h4>
          { flag && 
          <form onSubmit={handleSubmit}>

            <div className="form-group">
              <label>Current Password*</label>
              <input type="password" name="currentPassword" onChange={(e) => setCurrentPassword(e.target.value)} 
                className="form-control" required/>
              {error.currentPassword && <span className="text-danger">{error.currentPassword}</span>}
            </div>

            <div className="form-group">
              <label>New Password*</label>
              <input type="password" name="password" className="shadow-none" onChange={(e) => setPassword(e.target.value)} className="form-control" minLength={8} 
              placeholder="Password length must be at least 8 characters" required/>
              {error.password && <span className="text-danger">{error.password}</span>}
            </div>

            <div className="form-group">
              <label>Confirm Password*</label>
              <input type="password" name="rePassword" onChange={(e) => setRePassword(e.target.value)} className="form-control" minLength={8} 
              placeholder="Password length must be at least 8 characters" required/>
              {error.rePassword && <span className="text-danger">{error.rePassword}</span>}
            </div>

            <button type="submit" className="btn btn-primary" >
              Change Password
            </button>

          </form>                
          }
          {
          !flag && <div> <span className='pt-2 text-success h4'>Password changed successfully</span></div>
          }
    </div>  
  )
}
