import logo from './logo.svg';
import './App.css';
import {
  HashRouter,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import Navbar from './components/navbar';
import Home from './components/home';
import Footer from './components/footer';
import NotFound from './components/not_found';
import UserDashboard from './components/user_dashboard';
import Login from './components/login';
import Registration from './components/registration';
import Forget_Password from './components/forget_password';
import Reset_Password from './components/reset_password';
import Setting from './components/setting';
import RegistrationSuccess from './components/registration_success';
import CheckMeetingStatus from './components/check_meeting_status';
import JoinMeetingSuccess from './components/join_meeting_success';


function App() {
  const baseUrl = process.env.PUBLIC_URL;
  return (
    <BrowserRouter basename={baseUrl}>
      {/* <BrowserRouter basename={baseUrl}> */}
      <Navbar />
      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/user_dashboard" element={<UserDashboard />} />
        <Route path="/forget_password" element={<Forget_Password />} />
        <Route path="/reset_password" element={<Reset_Password />} />
        <Route path="/registration_success" element={<RegistrationSuccess />} />
        <Route path="/check_meeting_status" element={<CheckMeetingStatus />} />
        <Route path="/join_meeting_success" element={<JoinMeetingSuccess />} />
        <Route path="/setting" element={<Setting />} />
        <Route path='/*' element={<NotFound />} />

      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
