import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom';


export default function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");

    const [meetingId, setMeetingId] = useState("");
    const [passcode, setPasscode] = useState("");

    const loginUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_LOGIN_URL;

    const baseUrl = process.env.BASE_URL;

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('token')) {
            navigate("/user_dashboard")
        }
    }, [])


    const HandleLoginSubmit = async (event) => {
        event.preventDefault();

        console.log("username : " + username);
        console.log("password : " + password);

        axios.post(loginUrl, {
            username,
            password
        }).then(response => {
            console.log("JWT Token : " + response.data.jwt);
            console.log("Status code : " + response.status);
            localStorage.setItem("token", response.data.jwt);
            localStorage.setItem("meetingId", response.data.personalMeetingID);
            localStorage.setItem("name", response.data.name);
            localStorage.setItem("email", response.data.email);
            localStorage.setItem("passcode", response.data.passCode);
            localStorage.setItem("invitationLink", response.data.invitationLink);


            setMessage(response.message);
            console.log("Login Response " + JSON.stringify(response, null, 2));
            console.log("Jwt token: " + response.data.jwt);
            navigate("/user_dashboard");

        }).catch(error => {
            console.log("Error in request " + JSON.stringify(error, null, 2));
            setMessage(error.response.data.message);
        });

    };


    const HandleGuestJoinMeetingSubmit = async (event) => {
        let mId = meetingId.replace(/ /g, '');

        const guestMeetingUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GUEST_JOIN_MEETING_URL + '?' + 'meetingID=' + mId + '&passcode=' + passcode;

        axios.post(guestMeetingUrl).then(response => {

            console.log(" Join meeting response: " + JSON.stringify(response, null, 2));
            window.open(response.data.url, "_blank");
            navigate("/join_meeting_success");

        }).catch(error => {
            console.log("Error in request " + JSON.stringify(error, null, 2));

        });
    }

    return (
        <div className='container-fluid user-container border-top'>
            <main id="main">
                <section id="breadcrumbs" className="breadcrumbs">
                    <div className="container">
                        <ol> 
                            <li><a href={`${process.env.PUBLIC_URL}`}>Home</a></li>
                            <li>Login</li>
                        </ol>
                    </div>
                </section>
                <section className="tg-may-account-wrapp tg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 detail">
                                <h2 >Login</h2>
                                <p className="description">New to Boithok? <a href={`${process.env.PUBLIC_URL}/registration`}>Click here</a> to register</p>
                                <form className="tg-form" onSubmit={HandleLoginSubmit}>
                                    
                                    {message &&<div class="alert alert-danger">{message}</div>}
                                    
                                    <div class="form-group row">
                                        <label for="inputAddress2" class="col-sm-2 col-form-label">Email</label>
                                        <div class="col-sm-10">
                                            <input type="text" className="form-control shadow-none" value={username}
                                                onChange={(e) => setUsername(e.target.value)} placeholder="Enter your email" required/>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="inputPassword" class="col-sm-2 col-form-label">Password</label>
                                        <div class="col-sm-10">
                                            <input type="password" className="form-control shadow-none" value={password}
                                                onChange={(e) => setPassword(e.target.value)} placeholder="Enter your password" required/>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="inputPassword" class="col-sm-2 col-form-label"></label>
                                        <div class="col-sm-10">
                                            <button type="submit" className="btn btn-success">Sign In</button>
                                            <a href={`${process.env.PUBLIC_URL}/forget_password`} className="pl-3" style={{ float: "right" }}>Forgot Password?</a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-6" style={{ textAlign: "center" }}>
                                <img src="assets/img/login-img.png" alt="login" width="70%" heigth="100%" />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}
