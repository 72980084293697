import React from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { spaceAfter4digit } from './date_time';
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';


export default function StartMeeting() {
    const navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate("/login");
        }
    }, [])


    let meetingId = localStorage.getItem('meetingId');
    let jwt = localStorage.getItem('token');
    let name = localStorage.getItem('name');
    let passcode = localStorage.getItem('passcode');
    let invitationLink = localStorage.getItem('invitationLink');

    const [copyInvitLink, setcopyInvitLink] = useState('');

    const copyInvitationLink = () => {

        let copyInvitL = " Meeting Id : " + meetingId + "\n\n Passcode : " + passcode + "\n\n Invitation Link : " + invitationLink;
        setcopyInvitLink(copyInvitL);
        navigator.clipboard.writeText(copyInvitL);
        console.log(copyInvitL);
        toast.success('Invitation link copied', { autoClose: 2000 })
    }

    const meetingUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_START_MEETING;

    const header = {
        headers: {
            'Authorization': 'Bearer ' + jwt
        }
    }
    console.log("Authorization " + header.Authorization);

    const startMeeting = (e) => {
        console.log(jwt);
        console.log("Meeting url " + meetingUrl);
        console.log("header " + JSON.stringify(header));

        axios.get(meetingUrl, header)
            .then(response => {
                console.log(" Response : " + JSON.stringify(response));
                console.log(" Response : " + response.data.url);
                window.open(response.data.url, "_blank");
            }).catch(error => {
                console.log(" Error Here : " + JSON.stringify(error, null, 2));
                console.log(error.response.status);
                if (error.response.status == "401") {
                    localStorage.removeItem("token");
                    navigate("/login");
                }

            })
    }



    return (
        <div className="container">
            <div class='row col-md-12'>
                <h4>Start a meeting instantly</h4>

                <div className='col-md-3 col-sm-12 p-2'><b>Topic</b></div>
                <div className='col-md-9 col-sm-12 p-3'>{name}'s personal meeting room</div>

                <div className='col-md-3 col-sm-12 p-2'><b>Personal Meeting ID</b></div>
                <div className='col-md-9 col-sm-12 p-3'>{spaceAfter4digit(meetingId)}</div>

                <div className='col-md-3 col-sm-12 p-2'><b>Passcode</b></div>
                <div className='col-md-9 col-sm-12 p-3'>{passcode}</div>

                <div className='col-md-3 col-sm-12 p-2'><b>Invitation Link</b></div>
                <div className='col-md-9 col-sm-12 p-3'>{invitationLink}</div>


                {/* <h4 className='p-3'>Start meeting instantly</h4> */}
                <div className='col-md-3 col-sm-12 p-2'></div>
                <div className='col-md-9 col-sm-12 p-2'>
                    <button className='btn btn-success' onClick={startMeeting}>Start Meeting</button>&nbsp;&nbsp;
            <button className='btn btn-primary' data-toggle="modal" data-target="#exampleModal" >Copy Invitation</button>
                </div>

                <ToastContainer />

                <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Copy Invitation Information</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body invitaion-info">
                                <p>{name} is inviting you to join in meeting</p>
                                <p>Meeting Id : {meetingId}</p>
                                <p>Passcode : {passcode}</p>
                                <p>Invitation Link: {invitationLink}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={copyInvitationLink}>Copy Invitation Info</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>)
}
