import React from 'react'

export default function Footer() {
  return (
  <footer id="footer">
  <div className="footer-top">
    <div className="container">
      <div className="row">

        <div className="col-lg-4 col-md-6">
          <div className="footer-info">
            {/* <h3>Boithok</h3> */}
            <img src={`${process.env.PUBLIC_URL}/assets/img/bcc-logo.png`} alt="Registration" width="70px" heigth="100%" style={{marginBottom:"10px"}} />
              <p>                
                E-14/X, BCC Bhaban (ICT Tower) <br/>
                Agargaon Dhaka-1207, Bangladesh <br/><br/>
                <strong>Phone:</strong> <br/>
                <strong>Email:</strong> <br/>
              </p>
              <div className="social-links mt-3">
                <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
                <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
                <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
                <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
                <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
              </div>
          </div>
        </div>

        <div className="col-lg-2 col-md-6 footer-links">
          <h4>Site Map</h4>
          <ul>             
            <li><i className="bx bx-chevron-right"></i><a href="/#">Home</a></li>
            <li><i className="bx bx-chevron-right"></i><a href="/#about">About boithok</a></li>
            <li><i className="bx bx-chevron-right"></i><a href="/#services">Features</a></li>
            {/* <li><a href="#">Pricing</a></li> */}
            <li><i className="bx bx-chevron-right"></i><a href="/#faq">FAQ</a></li>
            <li><i className="bx bx-chevron-right"></i><a href="/#contact">Contact</a></li>
          </ul>
        </div>

        <div className="col-lg-2 col-md-6 footer-links">
        <h4>&nbsp;</h4>
          <ul>
            <li><i className="bx bx-chevron-right"></i> <a href="/login">Login</a></li>
            <li><i className="bx bx-chevron-right"></i> <a href="/registration">Registration</a></li>
            <li><i className="bx bx-chevron-right"></i> <a href="/user_dashboard">Dashboard</a></li>
          </ul>
        </div>

        <div className="col-lg-2 col-md-6 footer-links">
          <h4>Mobile Apps</h4>
          <ul>
            <li><i className="bx bx-chevron-right"></i> <a href="#">Andriod</a></li>
            <li><i className="bx bx-chevron-right"></i> <a href="#">Iphone</a></li>
            <li><i className="bx bx-chevron-right"></i> <a href="#">Windows App</a></li>
            <li><i className="bx bx-chevron-right"></i> <a href="#">MacOS App</a></li>
          </ul>
        </div>
        
        {/* <div className="col-lg-2 col-md-6 footer-links">
          <h4>Accessibility</h4>
          <ul>
            <li><i className="bx bx-chevron-right"></i> <a href="#">Terms & Conditions</a></li>
            <li><i className="bx bx-chevron-right"></i> <a href="#">Privacy Policy</a></li>
            <li><i className="bx bx-chevron-right"></i> <a href="#">Refund Policy</a></li>
            <li><i className="bx bx-chevron-right"></i> <a href="#">FAQ</a></li>
          </ul>
        </div> */}
        <div className="col-lg-2 col-md-6 footer-links">
          <h4>Useful Links</h4>
          <ul>
            <li><i className="bx bx-chevron-right"></i> <a href="https://www.bcc.gov.bd">Bangladesh Computer Council</a></li>
            <li><i className="bx bx-chevron-right"></i> <a href="https://www.ictd.gov.bd">ICT Division</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div className="container">
    <div className="copyright">
      &copy; Copyright <strong><span>Bangladesh Computer Council</span></strong>. All Rights Reserved
    </div>
  </div>
</footer>


  );
}
