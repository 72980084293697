import axios from 'axios';
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


export default function PricePlan() {

  const navigate = useNavigate();

  let jwt = localStorage.getItem('token');

  const header = {
      headers : {
          'Authorization' : 'Bearer '+ jwt
      }
  }    
  console.log("Authorization Meeting History" + header);
  const purchase_url = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PAYMENT_API;
  console.log("Purchase url : " + purchase_url);

  function purchasePackage(id){
      let p_url = purchase_url + id ;
      console.log("Purchase url---> : " + p_url);
      axios.get(p_url, header)
      .then(response =>{
          console.log(" URL " + response.data.url);
          window.open(response.data.url, "_self");
          console.log("Success ====>  " + JSON.stringify(response , null, 2));            
          console.log('Result ' +response);
      })
      .catch(error =>{
          console.log("Request is not get any response");
          console.log( "***************** Error Here : "+ JSON.stringify(error , null, 2));  
          if(error.response.status == "401")  {
              localStorage.removeItem("token");
              navigate("/login");
          }
      })
  }
  
  return (
    <div>
      <section id="pricing" className="pricing section-bg">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2>Pricing</h2>
          {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
        </div>

        <div className="row">

          <div className="col-lg-3 col-md-6">
            <div className="box" data-aos="zoom-in">
              <h3>Free</h3>
              <h4><sup>Tk</sup>0<span> / month</span></h4>
              <ul>
                  <li>No charge</li>
                  <li>Upto 30 Participants</li>
                  <li>Free 50 min meeting</li>
                  <li>No Attendence</li>
                  <li>No Chat History</li>
                  <li>No Video Recording</li>
                  <li>No Cloud Storage</li>
                  <li>No Standard Support</li>
              </ul>
              <div className="btn-wrap">
                <a href="#" className="btn-buy">Buy Now</a>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-4 mt-md-0">
            <div className="box featured" data-aos="zoom-in" data-aos-delay="100">
              <h3>Basic</h3>
              <h4><sup>Tk</sup>50<span> / Day</span></h4>
              <ul>
                <li>Daily charge 50Tk only</li>
                <li>Upto 100 Participants</li>
                <li>Unlimited Meetings</li>
                <li>Attendence Download</li>
                <li>Video Recording</li>
                <li>Chat History</li>
                <li>1GB Cloud Storage</li>
                <li>Standard Support</li>
              </ul>
              <div className="btn-wrap">
              <Link onClick={() => purchasePackage(2)} className="btn-buy">Buy Now</Link>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div className="box" data-aos="zoom-in" data-aos-delay="200">
              <h3>Pro</h3>
              <h4><sup>Tk</sup>1299<span> / month</span></h4>
              <ul>
              <li className='text-success fw-bold'>Save 201 Tk</li>
              <li>Upto 200 Participants</li>
              <li>Unlimited Meetings</li>
              <li>Attendence Download</li>
              <li>Video Recording</li>
              <li>Chat History</li>
              <li>3 GB Cloud Storage</li>
              <li>Standard Support</li>
              </ul>
              <div className="btn-wrap">
                <Link onClick={() => purchasePackage(3)} className="btn-buy">Buy Now</Link>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div className="box" data-aos="zoom-in" data-aos-delay="300">
              <span className="advanced">Advanced</span>
              <h3>Enterprise</h3>
              <h4><sup>Tk</sup>11680<span> / year</span></h4>
              <ul>
                  <li className='text-success fw-bold'>Save 6570 Tk</li>
                  <li>Upto 300 Participants</li>
                  <li>Unlimited Meetings</li>
                  <li>Attendence Download</li>
                  <li>Video Recording</li>
                  <li>Chat History</li>
                  <li>5 GB Cloud Storage</li>
                  <li>Standard Support</li>
              </ul>
              <div className="btn-wrap">
                <Link onClick={() => purchasePackage(4)} className="btn-buy">Buy Now</Link>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    </div>
  )
}
